import React, { useState } from 'react';
import './App.css';

function App() {

  const [isShown, setIsShown] = useState(false);

  function opacity0(e) {
    e.target.style.opacity = '0%';
  }

  function opacity1(e) {
    e.target.style.opacity = '100%';
  }

  return (
    <div className="App">
      <div className="App-gif">
      <img src="https://media.giphy.com/media/IcoMfdWwY26mBcX008/source.gif" alt="waiting"
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}>
      </img>
      {isShown && (
        <h1>
          COMING SOON
        </h1>
      )}
      </div>
    </div>
  );
}

export default App;
